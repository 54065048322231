// extracted by mini-css-extract-plugin
export var active = "plans-module--active--4e37a";
export var brand = "plans-module--brand--7fe05";
export var caption = "plans-module--caption--ead3c";
export var card = "plans-module--card--baec6";
export var check = "plans-module--check--ca37f";
export var checklistHeading = "plans-module--checklist-heading--8cfa8";
export var collapse = "plans-module--collapse--bda69";
export var compareLink = "plans-module--compare-link--35b9b";
export var content = "plans-module--content--c6080";
export var discount = "plans-module--discount--56200";
export var faqBtn = "plans-module--faq-btn--35acd";
export var interactPlus = "plans-module--interact-plus--10183";
export var interactPlusContent = "plans-module--interact-plus-content--fb4c0";
export var interactPlusTestimonial = "plans-module--interact-plus-testimonial--4bf52";
export var planDescription = "plans-module--plan-description--739e4";
export var plans = "plans-module--plans--6f524";
export var pricingTableItem = "plans-module--pricing-table-item--7fd35";
export var quote = "plans-module--quote--a1be4";
export var show = "plans-module--show--344a0";
export var testimonial = "plans-module--testimonial--58908";
export var testimonialBy = "plans-module--testimonial-by--8e5cf";
export var testimonialName = "plans-module--testimonial-name--6ba56";
export var times = "plans-module--times--a3278";
export var toggle = "plans-module--toggle--13aa7";
export var toggleContainer = "plans-module--toggle-container--8a586";